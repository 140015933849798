<template>
    <modal-lateral ref="modalOpcionesResponsive" :ancho="70" direccion="ltr" no-titulo>
        <div class="scroll-none overflow-auto px-3 pt-3" style="height:calc(100vh - 2px)">
            <div class="row mx-0 mt-2 a-center j-center mb-4 cr-pointer" @click="irA('mi-perfil')">
                <div class="circled-user p-1">
                    <img src="https://img.freepik.com/foto-gratis/joven-confiado_1098-20868.jpg?size=626&ext=jpg" class="obj-cover rounded-circle" width="100%" height="100%" />
                </div>
                <div class="col px-2 text-general">
                    <p class="f-18 f-500">Julián Carrillo</p>
                    <span class="text-gris2 f-500 f-14">Ir al perfil</span> <i class="icon-right-open f-15" />
                </div>
            </div>
            <!-- Access Routes -->
            <div class="row mx-0 px-2 a-center j-center mb-3 cr-pointer">
                <div class="text-center" style="width:28px;">
                    <i class="icon-shop f-20 text-general" />
                </div>
                <div class="col px-3 text-general">
                    Ir a la tienda
                </div>
            </div>
            <div class="row mx-0 px-2 a-center j-center mb-3 cr-pointer" @click="irA('pedidos')">
                <div class="text-center" style="width:28px;">
                    <i class="icon-order f-20 text-general" />
                </div>
                <div class="col px-3 text-general">
                    Mis pedidos
                </div>
            </div>
            <hr class="bg-gris mx-2" />
            <!-- Recompensas -->

            <div class="btn-invitana my-4 d-middle">
                <p class="col-auto font-omnes lh-22 text-white f-22">
                    Invita <br /> y gana
                </p>
                <img src="/img/ilustraciones/gaming/grupo_recompensas.png" class="img-grupo" width="100" height="100" />
            </div>

            <div class="row mx-0 px-2 mb-3">
                <div style="width:28px;" /> 
                <div class="col px-3 text-gris2 f-17">
                    Recompensas
                </div>
            </div>

            <!-- Recompensas -->
            <div class="row mx-0 px-2 a-center j-center mb-3 cr-pointer" @click="irA('recompensas')">
                <div class="text-center" style="width:28px;">
                    <i class="icon-gem f-20 text-general" />
                </div>
                <div class="col px-3 text-general">
                    Recompensas
                </div>
            </div>
            <div class="row mx-0 px-2 a-center j-center mb-3 cr-pointer" @click="irA()">
                <div class="text-center" style="width:28px;">
                    <i class="icon-discount f-17 text-general" />
                </div>
                <div class="col px-3 text-general">
                    cupones
                </div>
            </div>
            <div class="row mx-0 px-2 a-center j-center mb-3 cr-pointer" @click="irA('premios')">
                <div class="text-center" style="width:28px;">
                    <i class="icon-gift f-20 text-general" />
                </div>
                <div class="col px-3 text-general">
                    Premios
                </div>
            </div>
            <div class="row mx-0 px-2 a-center j-center mb-3 cr-pointer" @click="irA('mis-canjes')">
                <div class="text-center" style="width:28px;">
                    <i class="icon-bidirectional f-17 text-general" />
                </div>
                <div class="col px-3 text-general">
                    Mis canjes de premios
                </div>
            </div>
            <hr class="bg-gris mx-2" />
            <div class="row mx-0 px-2 mb-3">
                <div style="width:28px;" /> 
                <div class="col px-3 text-gris2 f-17">
                    Mi cuenta
                </div>
            </div>
            <div class="row mx-0 px-2 a-center j-center mb-3 cr-pointer">
                <div class="text-center" style="width:28px;">
                    <i class="icon-map-pin f-18 text-general" />
                </div>
                <div class="col px-3 text-general">
                    Mis direcciones
                </div>
            </div>
            <div class="row mx-0 px-2 a-center j-center mb-3 cr-pointer">
                <div class="text-center" style="width:28px;">
                    <i class="icon-client-cap f-18 text-general" />
                </div>
                <div class="col px-3 text-general">
                    Mis {{ $config.vendedor }}
                </div>
            </div>
            <div class="row mx-0 px-2 a-center j-center mb-3 cr-pointer" @click="irA('creditos')">
                <div class="text-center" style="width:28px;">
                    <i class="icon-credit-cancel f-15 text-general" />
                </div>
                <div class="col px-3 text-general">
                    Créditos
                </div>
                <span class="text-general f-600">$ 457.000</span>
            </div>
            <div class="row mx-0 px-2 a-center j-center mb-3 cr-pointer" @click="irA('convenio')">
                <div class="text-center" style="width:28px;">
                    <i class="icon-handshake f-18 text-general" />
                </div>
                <div class="col px-3 text-general">
                    Convenio de nómina 
                </div>
            </div>
            <hr class="bg-gris mx-2" />
            <div class="row mx-0 px-2 mb-3">
                <div style="width:28px;" /> 
                <div class="col px-3 text-gris2 f-17">
                    Información
                </div>
            </div>
            <div class="row mx-0 px-2 a-center j-center mb-3 cr-pointer" @click="irA('soporte')">
                <div class="text-center" style="width:28px;">
                    <i class="icon-life f-20 text-general" />
                </div>
                <div class="col px-3 text-general">
                    Ayuda
                </div>
            </div>
            <hr class="bg-gris mx-2" />
            <div class="row mx-0 px-2 a-center j-center cr-pointer">
                <div class="text-center" style="width:28px;">
                    <i class="icon-logout f-18 text-general" />
                </div>
                <div class="col px-3 text-general">
                    Cerrar sesion
                </div>
            </div>
        </div>
    </modal-lateral>
</template>

<script>
export default {
    data(){
        return {

        }
    },
    methods: {
        toggle(){
            this.$refs.modalOpcionesResponsive.toggle();
        }
    }
}
</script>
<style lang="scss" scoped>
.circled-user{
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
}

.btn-invitana{
    height: 80px;
    position: relative;
    border-radius: 12px;
    background: radial-gradient(farthest-side at 234px 72px, #CDB1FF, #A880FF,  #7D4AE8);

    .img-grupo{
        position: absolute;
        right: 23px;
        bottom: 4px;
    }
}
</style>